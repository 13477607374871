import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { getProfile, updateProfile } from 'services/user-events';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';
import { updatePassword } from 'services/users';
import { useNavigate } from 'react-router';

const UpdatePassword = () => {
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({
        email: '',
        password: '',
        new_password: '',
        new_password_confirmation: ''
    });
    const [errors, setErrors] = useState({});

    const authUser = useSelector((state) => state.auth.user);

    useEffect(() => {
        setInputs({
            ...inputs,
            email: authUser.email
        });
    }, [authUser]);

    const handleSave = () => {
        updatePassword(inputs)
            .then(() => {
                setInputs({
                    ...inputs,
                    password: '',
                    new_password: '',
                    new_password_confirmation: ''
                });
                enqueueSnackbar('Senha alterada com sucesso', { variant: 'success' });
            })
            .catch((error) => {
                setErrors(error.response.data.errors);
                if (error.response.data?.error) enqueueSnackbar(error.response.data.error, { variant: 'error' });
            });
    };

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h3">Alterar senha</Typography>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={1} display="flex" direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} md={8}>
                        <FormControl fullWidth>
                            <FormLabel component="legend">Senha atual</FormLabel>
                            <TextField
                                fullWidth
                                type="password"
                                variant="outlined"
                                value={inputs.password}
                                onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormControl fullWidth>
                            <FormLabel component="legend">Nova senha</FormLabel>
                            <TextField
                                fullWidth
                                type="password"
                                variant="outlined"
                                value={inputs.new_password}
                                onChange={(e) => setInputs({ ...inputs, new_password: e.target.value })}
                            />
                            <FormHelperText error={true}>{errors?.new_password}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormControl fullWidth>
                            <FormLabel component="legend">Confirmação de senha</FormLabel>
                            <TextField
                                fullWidth
                                type="password"
                                variant="outlined"
                                value={inputs.new_password_confirmation}
                                onChange={(e) => setInputs({ ...inputs, new_password_confirmation: e.target.value })}
                            />
                            <FormHelperText error={true}>{errors?.new_password_confirmation}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Stack spacing={2} direction="row">
                                <Button variant="text" onClick={() => navigate(-1)}>
                                    Voltar
                                </Button>
                                <AnimateButton>
                                    <Button type="button" variant="contained" onClick={() => handleSave()}>
                                        Alterar
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UpdatePassword;
