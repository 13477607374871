import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const admin = {
    id: 'admin',
    type: 'group',
    title: 'Menu Administrativo',
    children: [
        {
            id: 'index',
            title: 'Agendamentos',
            type: 'item',
            url: '/agendamentos',
            icon: CalendarMonthOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'locations',
            title: 'Locais',
            type: 'item',
            url: '/locais',
            icon: RoomOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'users',
            title: 'Usuários',
            type: 'item',
            url: '/usuarios',
            icon: PeopleAltOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'tags',
            title: 'Tags',
            type: 'item',
            url: '/tags',
            icon: LocalOfferOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'settings',
            title: 'Configurações',
            type: 'item',
            url: '/configuracoes',
            icon: SettingsOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        }
    ]
};

export default admin;
