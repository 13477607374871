import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import { information } from 'services/auth';
import jwt from 'jsonwebtoken';

// ==============================|| REACT DOM RENDER  ||============================== //
const jwt_secret = '6oi5vHLoH6xLTZwdnUjAbhVJaCCQRLk7DiFsgU2eiB6goPcseKCnDe38vQ8TY1Kv';
let token = localStorage.getItem('token');
if (token) {
    jwt.verify(token, jwt_secret, function (err) {
        if (err) {
            console.log(err);
            if (err.name !== 'NotBeforeError') {
                localStorage.removeItem('token');
                token = null;
            }
        }
        // else {
        //     if (decoded.iss !== URL + '/login') {
        //         localStorage.removeItem('token');
        //         token = null;
        //     }
        // }
    });
}
const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    );
};
if (token) {
    let unit = parseInt(localStorage.getItem('unit'));
    information()
        .then((res) => {
            store.dispatch({ type: 'SET_LOGIN', payload: res.data });
            store.dispatch({ type: 'SET_UNIT_USER', payload: unit });
        })
        .then(() => {
            render();
        });
} else {
    render();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
