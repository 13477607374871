import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    type: 'group',
    title: 'Menu Principal',
    children: [
        {
            id: 'default',
            title: 'Agendamentos',
            type: 'item',
            url: '/agendamentos',
            icon: CalendarMonthOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
