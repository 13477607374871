import { app } from './Api';

// const getLocations = async () => {
//     return app.get('location');
// };

const getTags = async () => {
    return app.get(`tag`);
};
const postTag = async (data) => {
    return app.post('tag', data);
};
const updateTag = async (id, data) => {
    return app.put('tag/' + id, data);
};
const deleteTag = async (id) => {
    return app.delete(`tag/${id}`);
};

export { getTags, postTag, updateTag, deleteTag };
