import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { getTags, postTag, updateTag, deleteTag } from 'services/tag';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';

const Tag = () => {
    const [tags, setTags] = useState([]);

    const [description, setDescription] = useState('');

    const [confirmDelete, setConfirmDelete] = useState({
        id: null,
        open: false
    });

    const handleAddTag = () => {
        if (!description) {
            enqueueSnackbar('Descrição é obrigatória', { variant: 'error' });
            return;
        }
        postTag({ description }).then((response) => {
            setTags([...tags, response.data]);
            setDescription('');
            enqueueSnackbar('Tag adicionada com sucesso', { variant: 'success' });
        });
    };

    const handleUpdateTag = (id) => {
        const tagDescription = tags.find((tag) => tag.id === id).description;
        updateTag(id, { description: tagDescription }).then(() => {
            setTags(tags.map((tag) => (tag.id === id ? { ...tag, description: tagDescription } : tag)));
            enqueueSnackbar('Tag atualizada com sucesso', { variant: 'success' });
        });
    };

    const handleDeleteTag = (id) => {
        deleteTag(id).then(() => {
            setTags(tags.filter((tag) => tag.id !== id));
            setConfirmDelete({ id: null, open: false });
            enqueueSnackbar('Tag removida com sucesso', { variant: 'success' });
        });
    };

    useEffect(() => {
        getTags().then((response) => {
            setTags(response.data.data);
        });
    }, []);

    return (
        <MainCard>
            <SnackbarProvider />
            <Grid container direction="row" justifyContent="flex-start" sx={{ mb: 8 }}>
                <Grid item xs={12}>
                    <Typography variant="h2" display="flex" alignItems="center">
                        <LocalOfferOutlinedIcon color="primary" sx={{ mr: 1 }} />
                        Tags
                    </Typography>
                    <Divider sx={{ mb: 4 }} />
                </Grid>
                <Grid item xs={12} md={8} lg={6}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>Nova Tag</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <FormControl fullWidth>
                                            <TextField value={description} onChange={(e) => setDescription(e.target.value)} size="small" />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Adicionar" placement="top">
                                            <IconButton color="success" onClick={() => handleAddTag()}>
                                                <AddCircleOutlineOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Tags disponíveis</TableCell>
                                    <TableCell width="120">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tags.map((tag) => (
                                    <TableRow key={tag.id}>
                                        <TableCell component="th" scope="row">
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={tag.description}
                                                    onChange={(e) => {
                                                        setTags(
                                                            tags.map((t) => (t.id === tag.id ? { ...t, description: e.target.value } : t))
                                                        );
                                                    }}
                                                    size="small"
                                                />
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Salvar" placement="top">
                                                <IconButton color="primary" onClick={() => handleUpdateTag(tag.id)}>
                                                    <SaveOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Deletar" placement="top">
                                                <IconButton color="error" onClick={() => setConfirmDelete({ id: tag.id, open: true })}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog
                open={confirmDelete.open}
                onClose={() => setConfirmDelete({ id: null, open: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Atenção!'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Você tem certeza que deseja excluir essa tag?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDelete({ id: null, open: false })}>Não</Button>
                    <Button onClick={() => handleDeleteTag(confirmDelete.id)}>Sim</Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
};

export default Tag;
