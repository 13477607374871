import { app } from './Api';

// const getLocations = async () => {
//     return app.get('location');
// };

const getUnits = async () => {
    return app.get(`unit`);
};

export { getUnits };
