import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const NewPassword = Loadable(lazy(() => import('views/pages/authentication/auth-forms/NewPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const FirstAccess = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <NewPassword />
        },
        {
            path: '/index',
            element: <NewPassword />
        }
    ]
};

export default FirstAccess;
