export const initialState = {
    page: 0,
    rowsPerPage: 10,
    nameLocation: '',
    location_id: ''
};

// ==============================|| AUTH REDUCER ||============================== //

const locationReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_LOCATION':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_LOCATION':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_NAMELOCATION_LOCATION_FILTER':
            return {
                ...state,
                nameLocation: actions.payload
            };
        case 'SET_LOCATION_ID':
            return {
                ...state,
                location_id: actions.payload
            };
        case 'SET_CLEAR_LOCATION_FILTER':
            return {
                ...state,
                nameLocation: ''
            };
        default:
            return state;
    }
};

export default locationReducer;
