import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Typography,
    Box,
    Modal,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Stack,
    Chip,
    Divider,
    FormHelperText,
    FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import moment from 'moment';
import 'moment/locale/pt-br';
import { deleteSchedule, checkIn, getScheduleSettings } from 'services/schedule';
import UpdateScheduleModal from './UpdateScheduleModal';
import { enqueueSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';

const ViewScheduleModal = ({ open, onClose, scheduleSelected, reloadEvents }) => {
    const [schedule, setSchedule] = useState({});

    const [editSchedule, setEditSchedule] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [settings, setSettings] = useState({});

    const [loading, setLoading] = useState(false);

    const authUser = useSelector((state) => state.auth.user);

    const isOwnerSchedule = (sched) => {
        return sched.user_id === authUser.id;
    };

    const canEditSchedule = (sched) => {
        return isOwnerSchedule(sched) && moment().isBefore(sched.end);
    };

    const validateCheckIn = () => {
        let start = moment(schedule.start).subtract(settings.check_tolerance_before.value, 'm');
        let end = moment(schedule.start).add(settings.check_tolerance_after.value, 'm');
        return moment().isBetween(start, end) && !schedule.checks?.find((check) => check.user_id === authUser.id);
    };

    const handleClose = () => {
        onClose();
    };

    const handleDelete = () => {
        setLoading(true);
        deleteSchedule(schedule.id).then((resp) => {
            setConfirmDelete(false);
            setSchedule({});
            handleClose();
            reloadEvents();
            setLoading(false);
            enqueueSnackbar('Agendamento excluído com sucesso!', { variant: 'success' });
        });
    };

    const handleEdit = () => {
        setEditSchedule(true);
    };

    const handleCheckIn = () => {
        checkIn(schedule.id).then((resp) => {
            handleClose();
            reloadEvents();
            enqueueSnackbar('Check-In realizado com sucesso!', { variant: 'success' });
        });
    };

    const checkInCompleted = () => {
        return schedule.checks?.find((check) => check.user_id === authUser.id);
    };

    useEffect(() => {
        if (open) setSchedule(scheduleSelected);
    }, [open]);

    useEffect(() => {
        getScheduleSettings().then(({ data }) => {
            setSettings(data);
        });
    }, []);

    return (
        <Modal open={open} onClose={handleClose} sx={{ height: '100vh', overflowY: 'scroll' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 0,
                    minWidth: 400,
                    maxWidth: 600,
                    maxHeight: '92vh',
                    overflowY: 'auto',
                    boxShadow: 24,
                    padding: 0
                }}
            >
                <Card>
                    <CardHeader
                        action={
                            <>
                                {canEditSchedule(schedule) && (
                                    <>
                                        <IconButton onClick={handleEdit}>
                                            <EditCalendarIcon />
                                        </IconButton>
                                        <IconButton onClick={() => setConfirmDelete(true)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                )}
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </>
                        }
                        title={schedule?.title}
                        subheader={moment(schedule?.start).locale('pt-br').format('LLL')}
                    />
                    <CardMedia component="img" height="240" image="/img/room.jpg" />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            {schedule?.description}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="h6">Anfitrião:</Typography>
                        <Stack direction="row" spacing={1}>
                            <Chip label={schedule.owner?.email} />
                        </Stack>
                        {schedule?.guests?.length > 0 && (
                            <>
                                <Divider sx={{ my: 1 }} />
                                <Typography variant="h6">Convidados:</Typography>
                                <Stack direction="row" spacing={1}>
                                    {schedule?.guests?.map((guest) => (
                                        <Chip key={guest.email} label={guest.email} sx={{ mr: 1 }} />
                                    ))}
                                </Stack>
                            </>
                        )}
                        <Divider sx={{ my: 2 }} />
                        {isOwnerSchedule(schedule) && (
                            <FormControl fullWidth>
                                <Button variant="contained" color="success" onClick={handleCheckIn} disabled={!validateCheckIn()}>
                                    Fazer Check-In
                                </Button>
                                {checkInCompleted() && <FormHelperText>Já foi feito o Check-In dessa reunião.</FormHelperText>}
                            </FormControl>
                        )}
                    </CardContent>
                </Card>
                <Dialog
                    open={confirmDelete}
                    onClose={() => setConfirmDelete(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'Atenção!'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Você tem certeza que deseja excluir esse agendamento?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {loading && (
                            <Box display="flex" justifyContent="flex-start" alignItems="center">
                                <CircularProgress size={20} sx={{ mr: 2 }} />
                                <Typography variant="legend" component="p" color="gray">
                                    Processando...
                                </Typography>
                            </Box>
                        )}
                        <Button onClick={() => setConfirmDelete(false)}>Não</Button>
                        <Button onClick={handleDelete}>Sim</Button>
                    </DialogActions>
                </Dialog>
                <UpdateScheduleModal
                    open={editSchedule}
                    onClose={() => setEditSchedule(false)}
                    schedule={schedule}
                    reloadEvents={() => {
                        reloadEvents();
                        handleClose();
                    }}
                />
            </Box>
        </Modal>
    );
};

export default ViewScheduleModal;
