import { app } from './Api';

const deleteEvent = async (id) => {
    return app.delete('schedule', id);
};

const getEvents = async (date = '', location_id = '') => {
    return app.get(`schedule?date=${date}&location_id=${location_id}`);
};

const getEventsByLocation = async (location_id) => {
    return app.get(`schedule?location_id=${location_id}`);
};

export { deleteEvent, getEvents, getEventsByLocation };
