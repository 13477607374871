import admin from './admin';
import user from './user';

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
    admin: [admin],
    user: [user]
};

export default menuItems;
