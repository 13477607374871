import { app } from './Api';

const getUsers = async (data) => {
    return app.get(`user`, { params: data });
};
const getUserById = async (id) => {
    return app.get(`user/${id}`);
};
const postUser = async (data) => {
    return app.post(`user`, data);
};
const updateUser = async (id, data) => {
    return app.put(`user/${id}`, data);
};
const deleteUser = async (id) => {
    return app.delete(`user/${id}`);
};
const updatePassword = async (data) => {
    return app.post(`update/password`, data);
};
const getProfile = async (user_id) => {
    return app.get('profile/' + user_id);
};
const updateProfile = async (user_id, data) => {
    return app.post('profile/' + user_id, data);
};

export { getUsers, getUserById, postUser, updateUser, deleteUser, updatePassword, getProfile, updateProfile };
