import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { app } from '../../../../services/Api';

const MicrosoftCallback = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);

    const redirectLogin = () => {
        const redirect = params.get('state')?.split('=')[1];
        if (redirect != 'null') {
            window.location.href = redirect;
        } else {
            navigate('/agendamentos');
        }
    };

    useEffect(() => {
        const fetchToken = async () => {
            const code = params.get('code');
            if (code) {
                try {
                    const response = await app.get(`microsoft/callback?code=${code}`);
                    localStorage.setItem('token', response.data.token);
                    dispatch({ type: 'SET_LOGIN', payload: response.data.user });
                    redirectLogin();
                } catch (error) {
                    console.error('Error fetching token', error);
                }
            }
        };

        fetchToken();
    }, [navigate]);

    return <div>Loading...</div>;
};

export default MicrosoftCallback;
