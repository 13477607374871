import * as React from 'react';
import { Divider, Typography, Grid } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { SnackbarProvider } from 'notistack';
import UpdateProfile from './components/UpdateProfile';
import UpdatePassword from './components/UpdatePassword';
import { useSelector } from 'react-redux';

const Profile = () => {
    const authUser = useSelector((state) => state.auth.user);

    return (
        <MainCard>
            <SnackbarProvider />
            <Typography variant="h2">Perfil</Typography>
            <Divider sx={{ my: 3 }} />
            <Grid container>
                <Grid item xs={12} md={8}>
                    <UpdateProfile />
                </Grid>
            </Grid>
            {!authUser.external_auth && (
                <>
                    <Divider sx={{ my: 4 }} />
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <UpdatePassword />
                        </Grid>
                    </Grid>
                </>
            )}
            <Divider sx={{ my: 2 }} />
        </MainCard>
    );
};

export default Profile;
