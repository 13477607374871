import * as React from 'react';
import { useState, useEffect } from 'react';
import { Grid, FormControl, FormLabel, TextField, Button } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { updateScheduleSettings, getScheduleSettings } from 'services/schedule';

const Setting = () => {
    const [checkToleranceBefore, setCheckToleranceBefore] = useState('5');
    const [checkToleranceAfter, setCheckToleranceAfter] = useState('15');

    const handleSave = () => {
        updateScheduleSettings([
            { key: 'check_tolerance_before', value: checkToleranceBefore },
            { key: 'check_tolerance_after', value: checkToleranceAfter }
        ])
            .then(() => {
                enqueueSnackbar('Configurações salvas com sucesso', { variant: 'success' });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getScheduleSettings().then((response) => {
            const { data } = response;
            if (data.check_tolerance_before) {
                setCheckToleranceBefore(data.check_tolerance_before.value);
            }
            if (data.check_tolerance_after) {
                setCheckToleranceAfter(data.check_tolerance_after.value);
            }
        });
    }, []);

    return (
        <MainCard title="Configurações" icon={<SettingsOutlinedIcon color="primary" />}>
            <SnackbarProvider />
            <FormControl fullWidth>
                <FormLabel component="legend" sx={{ pb: 2 }}>
                    Tolerância para o Check In
                </FormLabel>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <TextField
                            label="minutos antes do início"
                            type="number"
                            value={checkToleranceBefore}
                            onChange={(e) => setCheckToleranceBefore(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="minutos após o início"
                            type="number"
                            value={checkToleranceAfter}
                            onChange={(e) => setCheckToleranceAfter(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </FormControl>
            <Grid container justifyContent="start" sx={{ mt: 3 }}>
                <Grid item>
                    <AnimateButton>
                        <Button fullWidth type="button" variant="contained" onClick={() => handleSave()}>
                            Salvar
                        </Button>
                    </AnimateButton>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default Setting;
