import { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Grid,
    Divider,
    TextField,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    Skeleton,
    IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { searchSchedule } from 'services/schedule';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';

const FindScheduleModal = ({ open, onClose, handleGoToSchedule }) => {
    const [keyword, setKeyword] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setKeyword('');
        setResults([]);
        onClose();
    };

    const handleSearch = useDebouncedCallback((keyword) => {
        setKeyword(keyword);
    }, 1000);

    useEffect(() => {
        if (keyword) {
            searchSchedule(keyword).then(({ data }) => {
                setResults(data);
                setLoading(false);
            });
        }
    }, [keyword]);

    return (
        <Modal open={open} onClose={onClose} sx={{ height: '100vh', overflowY: 'scroll' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 0,
                    minWidth: 400,
                    maxWidth: 600,
                    maxHeight: '92vh',
                    overflowY: 'auto',
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Grid container spacing={1} display="flex" direction="row" justifyContent="space-between" alignItems="center">
                    <Typography color="GrayText" variant="h3" component="h1" fontWeight={100}>
                        Pesquisar agendamentos
                    </Typography>
                    <Grid item display="flex" justifyContent="flex-end">
                        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={1} display="flex" direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={1} display="flex" justifyContent="flex-end">
                        <SearchIcon fontSize="small" />
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            id="search"
                            label="Digite uma palavra-chave"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                                setLoading(true);
                                setResults([]);
                                handleSearch(e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                {loading && keyword != '' && (
                    <Grid container spacing={2} display="flex" alignItems="center">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <Box>
                                <Skeleton animation="wave" height={60} />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" height={60} />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" height={60} />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                            </Box>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={1} display="flex" alignItems="center">
                    <Grid item xs={1} display="flex" justifyContent="flex-end">
                        {!loading && keyword != '' && <ManageSearchIcon fontSize="small" />}
                    </Grid>
                    <Grid item xs={11}>
                        {(results.length > 0 && (
                            <TableContainer sx={{ border: 1, borderColor: 'grey.400', overflowY: 'scroll', height: '320px' }}>
                                <Table>
                                    <TableBody>
                                        {results?.map((schedule) => (
                                            <TableRow key={schedule.id}>
                                                <TableCell>
                                                    <Typography variant="h4" component="h2">
                                                        {schedule.title}
                                                    </Typography>
                                                    <Typography variant="legend" component="p" fontSize={12}>
                                                        {moment(schedule.start).format('DD/MM/YYYY HH:mm')}
                                                    </Typography>
                                                    <Typography variant="p" component="p">
                                                        {schedule.description}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Ir para reunião">
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() => {
                                                                handleGoToSchedule(schedule);
                                                            }}
                                                        >
                                                            <CalendarTodayIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )) ||
                            (!loading && keyword != '' && <Typography>Nenhum agendamento encontrado</Typography>)}
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default FindScheduleModal;
