import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    FormControl,
    TextField,
    Grid,
    Divider,
    Typography,
    Box,
    Button,
    Modal,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Card,
    Alert
} from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import GroupIcon from '@mui/icons-material/Group';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import PlaceIcon from '@mui/icons-material/Place';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { TagsInput } from 'react-tag-input-component';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/pt-br';
import { updateSchedule } from 'services/schedule';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import FormHelperText from '@mui/material/FormHelperText';
import { Lock } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

const UpdateScheduleModal = ({ open, onClose, schedule, reloadEvents }) => {
    const clearedFormInputs = {
        location: {},
        title: '',
        start_date: new Date(),
        end_date: new Date(),
        description: '',
        guests: [],
        notify: true,
        private: false
    };

    const [inputs, setInputs] = useState(clearedFormInputs);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const data = {
            location_id: inputs.location?.id,
            title: inputs.title,
            start: moment(inputs.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(inputs.end_date).format('YYYY-MM-DD HH:mm:ss'),
            description: inputs.description,
            guests: inputs.guests,
            notify: inputs.notify,
            private: inputs.private
        };
        setErrors({});
        setAlert({ open: false, message: '', severity: 'success' });
        updateSchedule(inputs.id, data)
            .then((resp) => {
                setInputs(clearedFormInputs);
                onClose();
                enqueueSnackbar('Agendamento alterado com sucesso', { variant: 'success' });
                reloadEvents();
            })
            .catch((error) => {
                error.response.data?.errors && setErrors(error.response.data.errors);
                error.response.data?.error && setAlert({ open: true, message: error.response.data.error, severity: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleClose = () => {
        setInputs(clearedFormInputs);
        setErrors({});
        onClose();
    };

    useEffect(() => {
        if (open) {
            setErrors({});
            setAlert({ open: false, message: '', severity: 'success' });
            setInputs({
                ...inputs,
                id: schedule.id,
                title: schedule.title,
                start_date: moment(schedule.start).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(schedule.end).format('YYYY-MM-DD HH:mm:ss'),
                description: schedule.description || '',
                guests: schedule.guests?.map((guest) => guest.email),
                location: schedule.location,
                private: schedule.private ? true : false
            });
        }
    }, [open]);

    return (
        <Modal open={open} onClose={handleClose} sx={{ height: '100vh', overflowY: 'scroll' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 0,
                    minWidth: 400,
                    maxWidth: 600,
                    maxHeight: '92vh',
                    overflowY: 'auto',
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Grid container spacing={1} display="flex" direction="row" justifyContent="space-between" alignItems="center">
                    <Typography color="GrayText" variant="h3" component="h1" fontWeight={100}>
                        Editar agendamento
                    </Typography>
                    <Grid item display="flex" justifyContent="flex-end">
                        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                {alert.message && (
                    <>
                        <Alert severity={alert.severity} variant="filled" sx={{ display: alert.open ? 'flex' : 'none' }}>
                            {alert.message}
                        </Alert>
                        <Divider sx={{ my: 1 }} />
                    </>
                )}
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl required fullWidth>
                                <TextField
                                    size="normal"
                                    inputProps={{ style: { fontSize: 16, color: '#333' } }}
                                    variant="filled"
                                    name="title"
                                    placeholder="Adicionar título"
                                    value={inputs.title}
                                    onChange={(e) => {
                                        setInputs({ ...inputs, title: e.target.value });
                                    }}
                                />
                                <FormHelperText error={true}>{errors.title}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <PlaceIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    name="location"
                                    value={`${inputs.location?.description}`}
                                    placeholder="Local"
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <AccessTimeIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl required fullWidth>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
                                    <MobileDateTimePicker
                                        variant="standard"
                                        label="Data de Início"
                                        onChange={(value) => {
                                            setInputs({ ...inputs, start_date: value });
                                        }}
                                        type="date"
                                        name="start_date"
                                        id="start_date"
                                        ampm={false}
                                        value={inputs.start_date}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        inputFormat={'DD/MM/yyyy HH:mm'}
                                        disablePast
                                    />
                                </LocalizationProvider>
                                <FormHelperText error={true}>{errors.start_date}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <AccessTimeIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl required fullWidth>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
                                    <MobileDateTimePicker
                                        variant="standard"
                                        label="Data de Fim"
                                        onChange={(value) => {
                                            setInputs({ ...inputs, end_date: value });
                                        }}
                                        type="date"
                                        name="end_date"
                                        id="end_date"
                                        ampm={false}
                                        value={inputs.end_date}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        inputFormat={'DD/MM/yyyy HH:mm'}
                                        disablePast
                                    />
                                </LocalizationProvider>
                                <FormHelperText error={true}>{errors.end_date}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <NotesIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl required fullWidth>
                                <TextField
                                    type="text"
                                    multiline
                                    rows={3}
                                    name="description"
                                    value={inputs.description}
                                    placeholder="Adicionar descrição"
                                    onChange={(e) => {
                                        setInputs({ ...inputs, description: e.target.value });
                                    }}
                                />
                                <FormHelperText error={true}>{errors.description}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <GroupIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl required fullWidth>
                                <TagsInput
                                    name="guests"
                                    value={inputs.guests}
                                    placeHolder="Adicionar convidados"
                                    onChange={(e) => {
                                        setInputs({ ...inputs, guests: e });
                                    }}
                                />
                                <FormHelperText error={true}>
                                    {Object.keys(errors).map((key) => String(key).indexOf('guests') > -1 && errors[key])}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <NotificationsIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={11}>
                            <Card variant="outlined" sx={{ px: 2 }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={inputs.notify}
                                                onChange={(event) => setInputs({ ...inputs, notify: event.target.checked })}
                                            />
                                        }
                                        label="Enviar notificação por email"
                                    />
                                </FormGroup>
                            </Card>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <Lock fontSize="small" />
                        </Grid>
                        <Grid item xs={11}>
                            <Card variant="outlined" sx={{ px: 2 }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={inputs.private}
                                                onChange={(event) => setInputs({ ...inputs, private: event.target.checked })}
                                            />
                                        }
                                        label="Reunião privada"
                                    />
                                </FormGroup>
                            </Card>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} display="flex" justifyContent="space-between">
                            <Box display="flex" justifyContent="flex-start" alignItems="center">
                                {loading && (
                                    <>
                                        <CircularProgress size={24} sx={{ mr: 2 }} />
                                        <Typography variant="legend" component="p" color="gray">
                                            Processando...
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            <Button variant="contained" startIcon={<EditCalendarIcon />} type="submit" size="large" disabled={loading}>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};

export default UpdateScheduleModal;
