import { combineReducers } from 'redux';
import authReducer from './authReducer';

// reducer import
import customizationReducer from './customizationReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import locationReducer from './locationReducer';
import userEventReducer from './userEventReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    auth: authReducer,
    user: userReducer,
    users: usersReducer,
    location: locationReducer,
    userEvent: userEventReducer
});

export default reducer;
