import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconLogout, IconSettings, IconUser } from '@tabler/icons';
import { DateRange } from '@mui/icons-material';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    const [selectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const name = useSelector((state) => state.auth.user.name) || '';
    const id = useSelector((state) => state.auth.user.id) || '';
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        localStorage.removeItem('token');
        navigate({ pathname: '/login' });
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    // const handleListItemClick = (event, index, route = '') => {
    //     setSelectedIndex(index);
    //     handleClose(event);

    //     if (route && route !== '') {
    //         navigate(route);
    //     }
    // };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    border: '0',
                    backgroundColor: '#2196f3',
                    '& svg': {
                        stroke: '#FFF'
                    },
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        background: `#2196f3 !important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: '#FFF'
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                            bgcolor: '#FFF'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        aria-label="recipe"
                        style={{ color: '#808080' }}
                    >
                        {name.length > 0 ? name[0] : 'R'}
                    </Avatar>
                    // <Avatar
                    //     src={User1}
                    //     sx={{
                    //         ...theme.typography.mediumAvatar,
                    //         margin: '8px 0 8px 8px !important',
                    //         cursor: 'pointer'
                    //     }}
                    //     ref={anchorRef}
                    //     aria-controls={open ? 'menu-list-grow' : undefined}
                    //     aria-haspopup="true"
                    //     color="inherit"
                    // />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">Bem-vindo </Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {name}!
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Box sx={{ p: 2 }}>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    borderRadius: `${customization.borderRadius}px`,
                                                    ':hover': {
                                                        bgcolor: '#2196f3',
                                                        color: '#FFF',
                                                        '.MuiListItemIcon-root': {
                                                            color: '#FFF'
                                                        },
                                                        '.MuiTypography-root': {
                                                            color: '#FFF'
                                                        }
                                                    },
                                                    '&.MuiListItemButton-root': {
                                                        '&.Mui-selected': {
                                                            bgcolor: '#2196f3',
                                                            color: '#FFF',
                                                            '.MuiListItemIcon-root': {
                                                                color: '#FFF'
                                                            },
                                                            '.MuiListItemText-root': {
                                                                color: '#FFFFF'
                                                            }
                                                        }
                                                    },
                                                    '.MuiListItemIcon-root': {
                                                        color: '#2196f3'
                                                    },
                                                    '.MuiTypography-root': {
                                                        '&:hover': {
                                                            color: '#FFFFFF'
                                                        }
                                                    }
                                                }}
                                                selected={selectedIndex === 1}
                                                onClick={() => navigate(`/perfil`)}
                                            >
                                                <ListItemIcon>
                                                    <IconUser stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Grid container spacing={1} justifyContent="space-between">
                                                            <Grid item>
                                                                <Typography variant="body2">Perfil</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{
                                                    borderRadius: `${customization.borderRadius}px`,
                                                    ':hover': {
                                                        bgcolor: '#2196f3',
                                                        color: '#FFF',
                                                        '.MuiListItemIcon-root': {
                                                            color: '#FFF'
                                                        },
                                                        '.MuiTypography-root': {
                                                            color: '#FFF'
                                                        }
                                                    },
                                                    '&.MuiListItemButton-root': {
                                                        '&.Mui-selected': {
                                                            bgcolor: '#2196f3',
                                                            color: '#FFF',
                                                            '.MuiListItemIcon-root': {
                                                                color: '#FFF'
                                                            },
                                                            '.MuiListItemText-root': {
                                                                color: '#FFFFF'
                                                            }
                                                        }
                                                    },
                                                    '.MuiListItemIcon-root': {
                                                        color: '#2196f3'
                                                    },
                                                    '.MuiListItemText-root': {
                                                        color: '#FFFFF'
                                                    }
                                                }}
                                                selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Sair</Typography>} />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
