import React, { useEffect, useState, createRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';

import FullCalendar from '@fullcalendar/react';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import CreateScheduleModal from './components/CreateScheduleModal';
import FindLocationSchedule from './components/FindLocationSchedule';
import ReadQRCodeModal from './components/ReadQRCodeModal';
import ViewScheduleModal from './components/ViewScheduleModal';
import FindScheduleModal from './components/FindScheduleModal';

import { Grid, FormControl, InputLabel, Select, MenuItem, Divider, Tooltip, Box, Fab, Typography, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MainCard from 'ui-component/cards/MainCard';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SearchIcon from '@mui/icons-material/Search';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CalendarMonth from '@mui/icons-material/CalendarMonth';

import { getUnits } from 'services/unit';
import { getLocationsByUnit } from 'services/location';
import { getEventsByLocation } from 'services/user-events';
import { getProfile } from 'services/users';

const Calendar = () => {
    const [unit, setUnit] = useState({});
    const [location, setLocation] = useState({});
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    const [units, setUnits] = useState([]);
    const [locations, setLocations] = useState([]);

    const [startDateSelected, setStartDateSelected] = useState();
    const [endDateSelected, setEndDateSelected] = useState();
    const [scheduleSelected, setScheduleSelected] = useState();

    const [createScheduleModal, setCreateScheduleModal] = useState(false);
    const [findLocationModal, setFindLocationModal] = useState(false);
    const [readQRCodeModal, setReadQRCodeModal] = useState(false);
    const [viewScheduleModal, setViewScheduleModal] = useState(false);
    const [findScheduleModal, setFindScheduleModal] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);

    const params = useParams();
    const calendarRef = createRef();

    const authUser = useSelector((state) => state.auth.user);

    const userCanViewEvent = (event) => {
        if (!event.extendedProps.private) return true;
        return event.extendedProps.user_id === authUser.id || event.extendedProps.guests?.find((x) => x.email == authUser.email);
    };

    const getLocations = async () => {
        if (unit?.id) {
            getLocationsByUnit(unit.id).then((resp) => {
                setLocations(resp.data.data);
            });
        }
    };

    const getEvents = () => {
        if (location?.id) {
            setLoading(true);
            getEventsByLocation(location.id).then((resp) => {
                setEvents([...resp.data]);
                setLoading(false);
            });
        }
    };

    const handleSelectTime = (info) => {
        setStartDateSelected(info.startStr);
        setEndDateSelected(info.endStr);
        setCreateScheduleModal(true);
    };

    const handleFindAddLocation = (location, startDate, endDate) => {
        setUnit({ ...units.find((u) => u.id === location.unit_id) });
        setLocation({ ...location });
        getEvents();
        handleSelectTime({ startStr: startDate, endStr: endDate });
        handleDateChange(startDate);
    };

    const handleGoToSchedule = (schedule) => {
        setUnit({ ...units.find((u) => u.id === schedule.location.unit_id) });
        setLocation({ ...schedule.location });
        getEvents();
        handleDateChange(schedule.start);
        setScheduleSelected(schedule);
        setViewScheduleModal(true);
    };

    const handleLocation = (location, startDate) => {
        setUnit({ ...units.find((u) => u.id === location.unit_id) });
        setLocation({ ...location });
        getEvents();
        handleDateChange(startDate);
    };

    const handleDateChange = (startDate) => {
        const calApi = calendarRef.current?.getApi();
        if (calApi) calApi.gotoDate(startDate);
    };

    const handleEventClick = (event) => {
        if (event.extendedProps && !event.extendedProps.holiday) {
            if (userCanViewEvent(event)) {
                setScheduleSelected({
                    id: event.id,
                    user_id: event.extendedProps.user_id,
                    title: event.title,
                    start: event.start,
                    end: event.end,
                    description: event.extendedProps.description,
                    guests: event.extendedProps.guests,
                    checks: event.extendedProps.checks,
                    location: event.extendedProps.location,
                    owner: event.extendedProps.owner,
                    private: event.extendedProps.private
                });
                setViewScheduleModal(true);
            } else {
                enqueueSnackbar('Você não tem permissão para visualizar este agendamento', { variant: 'warning' });
            }
        }
    };

    const renderEventContent = ({ event, view: { type } }) => {
        const startStr = moment(event.startStr).format('HH:mm');
        const endStr = moment(event.endStr).format('HH:mm');
        return (
            <Box
                sx={{ cursor: 'pointer', borderRadius: '8px', padding: '0' }}
                className={`${event.classNames[0]} ${type}`}
                onClick={() => handleEventClick(event)}
                onTouchStart={() => handleEventClick(event)}
            >
                <div className={`fc-event-title ${event.classNames[0]}`}>{userCanViewEvent(event) ? event.title : '(Reservado)'}&nbsp;</div>
                <div className={`fc-event-time ${event.classNames[0]}`}>
                    {`${startStr}`} - {`${endStr}`}
                </div>
            </Box>
        );
    };

    const [workSpec, setWorkSpec] = useState([
        {
            daysOfWeek: [1, 2, 3, 4, 5],
            startTime: '07:00',
            endTime: '20:00'
        }
    ]);

    const workMin = () => {
        return workSpec
            .map((item) => item.startTime)
            .sort()
            .shift();
    };

    const workMax = () => {
        return workSpec
            .map((item) => item.endTime)
            .sort()
            .pop();
    };

    const workDays = () => {
        return [...new Set(workSpec.flatMap((item) => item.daysOfWeek))];
    };

    const hideDays = () => {
        return [...Array(7).keys()].filter((day) => !workDays().includes(day));
    };

    useEffect(() => {
        if (locations.length > 0 && params.id) {
            const locationParam = locations.find((u) => u.id == params.id);
            if (locationParam) setLocation(locationParam);
            else enqueueSnackbar('Sala não encontrada', { variant: 'error' });
        }
    }, [locations]);

    useEffect(() => {
        if (units.length > 0 && params.unit_id) {
            const unitParam = units.find((u) => u.id == params.unit_id);
            if (unitParam) setUnit(unitParam);
            else enqueueSnackbar('Unidade não encontrada', { variant: 'error' });
        }
    }, [units]);

    useEffect(() => {
        getEvents();
    }, [location]);

    useEffect(() => {
        getLocations();
    }, [unit]);

    useEffect(() => {
        getUnits().then((resp) => {
            setUnit({});
            setLocation({});
            setUnits(resp.data.data);
        });
    }, []);

    useEffect(() => {
        getProfile(authUser.id).then((response) => {
            const { data } = response;
            if (data.days_of_week && data.start_time && data.end_time)
                setWorkSpec([
                    {
                        daysOfWeek: data.days_of_week,
                        startTime: moment(data.start_time).format('HH:mm'),
                        endTime: moment(data.end_time).format('HH:mm')
                    }
                ]);
            if (data.default_unit_id) {
                const unitParam = units.find((u) => u.id == data.default_unit_id);
                if (unitParam) setUnit(unitParam);
            }
        });
    }, [units]);

    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth));
        return () => {
            window.removeEventListener('resize', () => setWidth(window.innerWidth));
        };
    }, []);

    return (
        <MainCard>
            <SnackbarProvider />
            <Grid container display="flex" direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <CalendarMonth sx={{ mr: 2 }} color="primary" />
                        <Typography variant="h4">Olá, bem-vindo ao agendamento de salas!</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} display="flex" justifyContent={width <= 600 ? 'center' : 'flex-end'}>
                    <Box sx={{ '& > :not(style)': { m: 1 } }}>
                        <Tooltip title="Verificar agendamento">
                            <Fab variant="extended" color="primary" onClick={() => setReadQRCodeModal(true)}>
                                <QrCode2Icon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Pesquisar agendamentos">
                            <Fab variant="extended" color="primary" onClick={() => setFindScheduleModal(true)}>
                                <ContentPasteSearchIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Procurar salas disponíveis">
                            <Fab variant="extended" color="primary" xs={{ m: 2 }} onClick={() => setFindLocationModal(true)}>
                                <SearchIcon />
                            </Fab>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth style={{ flex: '1' }}>
                        <InputLabel id="unit-label">Unidade</InputLabel>
                        <Select
                            labelId="unit-label"
                            label="Unidade"
                            value={unit}
                            onChange={(e) => {
                                setLocation({});
                                setUnit(e.target.value);
                            }}
                            renderValue={(value) => value.description}
                        >
                            {units?.map((unit) => (
                                <MenuItem key={unit.id} value={unit}>
                                    {unit.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required fullWidth style={{ flex: '1' }}>
                        <InputLabel id="location-label">Local</InputLabel>
                        <Select
                            labelId="location-label"
                            label="Local"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            renderValue={(value) => value.description}
                        >
                            {locations?.map((location) => (
                                <MenuItem key={location.id} value={location}>
                                    {location.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {location?.id && (
                    <Grid item xs={12} sx={{ position: 'relative' }}>
                        {loading && (
                            <Grid
                                container
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    zIndex: 100,
                                    top: 0,
                                    left: 0,
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)'
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                        <FullCalendar
                            ref={calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView={'timeGridWeek'}
                            selectable={true}
                            selectMirror={true}
                            locale={ptBrLocale}
                            titleFormat={
                                width <= 768
                                    ? { year: 'numeric', month: 'numeric', day: 'numeric' }
                                    : { year: 'numeric', month: 'long', day: 'numeric' }
                            }
                            slotLabelFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                omitZeroMinute: false
                            }}
                            nowIndicator={true}
                            allDaySlot={false}
                            longPressDelay={1000}
                            slotEventOverlap={false}
                            themeSystem="bootstrap"
                            select={handleSelectTime}
                            events={events}
                            eventContent={renderEventContent}
                            businessHours={workSpec}
                            slotMinTime={workMin()}
                            slotMaxTime={workMax()}
                            hiddenDays={hideDays()}
                            contentHeight={'auto'}
                            views={{
                                timeGridWeek: {
                                    type: 'timeGridWeek',
                                    duration: { days: width <= 768 ? 1 : 7 }
                                }
                            }}
                        />
                    </Grid>
                )}
                {!location?.id && (
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-evenly"
                        alignItems="center"
                        style={{ marginTop: '60px', marginBottom: '60px' }}
                    >
                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
                            <img
                                src="https://www.gstatic.com/meet/user_edu_get_a_link_light_90698cd7b4ca04d3005c962a3756c42d.svg"
                                alt="agendamento"
                                width="200px"
                            />
                        </Box>
                        <Box>
                            <img
                                src="https://www.gstatic.com/meet/user_edu_scheduling_light_b352efa017e4f8f1ffda43e847820322.svg"
                                alt="agendamento"
                                width="200px"
                            />
                        </Box>
                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
                            <img
                                src="https://www.gstatic.com/meet/user_edu_safety_light_e04a2bbb449524ef7e49ea36d5f25b65.svg"
                                alt="agendamento"
                                width="200px"
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
            <CreateScheduleModal
                open={createScheduleModal}
                onClose={() => setCreateScheduleModal(false)}
                startDate={startDateSelected}
                endDate={endDateSelected}
                location={location}
                handleLocation={handleLocation}
                fetchEvents={getEvents}
            />
            <FindScheduleModal
                open={findScheduleModal}
                onClose={() => setFindScheduleModal(false)}
                handleGoToSchedule={handleGoToSchedule}
            />
            <FindLocationSchedule
                open={findLocationModal}
                onClose={() => setFindLocationModal(false)}
                unit={unit}
                units={units}
                handleFindAddLocation={handleFindAddLocation}
            />
            <ReadQRCodeModal
                open={readQRCodeModal}
                onClose={() => setReadQRCodeModal(false)}
                user={authUser}
                handleGoToSchedule={handleGoToSchedule}
                handleLocation={handleLocation}
            />
            <ViewScheduleModal
                open={viewScheduleModal}
                onClose={() => setViewScheduleModal(false)}
                scheduleSelected={scheduleSelected}
                reloadEvents={getEvents}
            />
        </MainCard>
    );
};

export default Calendar;
