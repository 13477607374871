// material-ui
import { Typography } from '@mui/material';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return <Typography variant="h4">Inova</Typography>;
};
const LogoLogin = () => {
    return <Typography variant="h4">Inova</Typography>;
};

export { Logo, LogoLogin };
