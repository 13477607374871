import { app } from './Api';

// const getLocations = async () => {
//     return app.get('location');
// };

const postLocation = async (data) => {
    return app.post(`location`, data);
};
const getLocations = async (
    page = '',
    qtdPerPage = '',
    tags = '',
    capacity = '',
    start_date = '',
    end_date = '',
    unit_id = '',
    inactive = ''
) => {
    return app.get(
        `location?page=${page}&qtdPerPage=${qtdPerPage}&tags=${tags}&capacity=${capacity}&start_date=${
            start_date == null ? '' : start_date
        }&end_date=${end_date == null ? '' : end_date}&unit_id=${unit_id}&inactive=${inactive}`
    );
};
const getLocationsByUnit = async (unitId) => {
    return app.get(`location?unit_id=${unitId}`);
};
const updateLocation = async (id, data) => {
    return app.put(`location/${id}`, data);
};
const deleteLocation = async (id) => {
    return app.delete(`location/${id}`);
};
const getLocationPerId = async (id) => {
    return app.get(`location/${id}`);
};
const changeStatusLocation = async (id) => {
    return app.patch(`location/${id}/change`);
};

export { getLocations, postLocation, updateLocation, deleteLocation, getLocationPerId, changeStatusLocation, getLocationsByUnit };
