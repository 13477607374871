import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { getProfile, updateProfile } from 'services/users';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';
import { getUnits } from 'services/unit';
import { useNavigate } from 'react-router';

const UpdateProfile = () => {
    const navigate = useNavigate();

    const [daysOfWeek, setDaysOfWeek] = useState({
        sunday: false,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false
    });

    const [startTime, setStartTime] = useState(moment('2021-01-01T07:00:00'));
    const [endTime, setEndTime] = useState(moment('2021-01-01T20:00:00'));

    const authUser = useSelector((state) => state.auth.user);

    const [unit, setUnit] = useState({});
    const [units, setUnits] = useState([]);

    useEffect(() => {
        getUnits().then((response) => {
            setUnits(response.data?.data);
        });
    }, []);

    useEffect(() => {
        getProfile(authUser.id).then((response) => {
            const { data } = response;
            if (data.days_of_week && data.start_time && data.end_time) {
                setDaysOfWeek({
                    sunday: data.days_of_week.includes(0),
                    monday: data.days_of_week.includes(1),
                    tuesday: data.days_of_week.includes(2),
                    wednesday: data.days_of_week.includes(3),
                    thursday: data.days_of_week.includes(4),
                    friday: data.days_of_week.includes(5),
                    saturday: data.days_of_week.includes(6)
                });
                setStartTime(moment(data.start_time));
                setEndTime(moment(data.end_time));
            }
            if (data.default_unit_id) {
                const unitParam = units.find((u) => u.id == data.default_unit_id);
                if (unitParam) setUnit(unitParam);
            }
        });
    }, [units]);

    const encodeDays = () => {
        const days = Object.entries(daysOfWeek)
            .map(([day, value]) => {
                switch (day) {
                    case 'sunday':
                        return value ? 0 : null;
                    case 'monday':
                        return value ? 1 : null;
                    case 'tuesday':
                        return value ? 2 : null;
                    case 'wednesday':
                        return value ? 3 : null;
                    case 'thursday':
                        return value ? 4 : null;
                    case 'friday':
                        return value ? 5 : null;
                    case 'saturday':
                        return value ? 6 : null;
                    default:
                        return null;
                }
            })
            .filter((day) => day !== null);

        return days;
    };

    const handleSave = () => {
        const encodedDays = encodeDays();
        updateProfile(authUser.id, {
            days_of_week: encodedDays,
            start_time: '2021-01-01 ' + startTime?.format('HH:mm:ss'),
            end_time: '2021-01-01 ' + endTime?.format('HH:mm:ss'),
            default_unit_id: unit.id
        })
            .then((response) => {
                console.log(response);
                enqueueSnackbar('Preferências atualizadas com sucesso', { variant: 'success' });
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar('Erro ao atualizar preferências', { variant: 'error' });
            });
    };

    return (
        <>
            <Typography variant="h3">Configurações da agenda</Typography>
            <Divider sx={{ my: 1 }} />
            <FormControl fullWidth>
                <FormLabel component="legend">Unidade padrão</FormLabel>
                <Select
                    variant="standard"
                    label="Unidade"
                    value={unit}
                    onChange={(e) => {
                        setUnit(e.target.value);
                    }}
                    renderValue={(value) => value.description}
                >
                    {units?.map((unit) => (
                        <MenuItem key={unit.id} value={unit}>
                            {unit.description}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Divider sx={{ my: 1 }} />
            <FormControl sx={{ my: 3 }} component="fieldset" variant="standard" fullWidth>
                <FormLabel component="legend">Dias da semana</FormLabel>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        labelPlacement="bottom"
                        color="secondary"
                        control={<Checkbox />}
                        label="Domingo"
                        onChange={(e) => setDaysOfWeek({ ...daysOfWeek, sunday: e.target.checked })}
                        checked={daysOfWeek.sunday}
                    />
                    <FormControlLabel
                        labelPlacement="bottom"
                        color="secondary"
                        control={<Checkbox />}
                        label="Segunda"
                        onChange={(e) => setDaysOfWeek({ ...daysOfWeek, monday: e.target.checked })}
                        checked={daysOfWeek.monday}
                    />
                    <FormControlLabel
                        labelPlacement="bottom"
                        color="secondary"
                        control={<Checkbox />}
                        label="Terça"
                        onChange={(e) => setDaysOfWeek({ ...daysOfWeek, tuesday: e.target.checked })}
                        checked={daysOfWeek.tuesday}
                    />
                    <FormControlLabel
                        labelPlacement="bottom"
                        color="secondary"
                        control={<Checkbox />}
                        label="Quarta"
                        onChange={(e) => setDaysOfWeek({ ...daysOfWeek, wednesday: e.target.checked })}
                        checked={daysOfWeek.wednesday}
                    />
                    <FormControlLabel
                        labelPlacement="bottom"
                        color="secondary"
                        control={<Checkbox />}
                        label="Quinta"
                        onChange={(e) => setDaysOfWeek({ ...daysOfWeek, thursday: e.target.checked })}
                        checked={daysOfWeek.thursday}
                    />
                    <FormControlLabel
                        labelPlacement="bottom"
                        color="secondary"
                        control={<Checkbox />}
                        label="Sexta"
                        onChange={(e) => setDaysOfWeek({ ...daysOfWeek, friday: e.target.checked })}
                        checked={daysOfWeek.friday}
                    />
                    <FormControlLabel
                        labelPlacement="bottom"
                        color="secondary"
                        control={<Checkbox />}
                        label="Sábado"
                        onChange={(e) => setDaysOfWeek({ ...daysOfWeek, saturday: e.target.checked })}
                        checked={daysOfWeek.saturday}
                    />
                </FormGroup>
            </FormControl>
            <FormControl fullWidth>
                <FormLabel component="legend" sx={{ pb: 2 }}>
                    Horários
                </FormLabel>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker
                                label="Início"
                                format="HH:mm"
                                ampm={false}
                                value={startTime}
                                onChange={(newValue) => setStartTime(newValue)}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                                minutesStep={60}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker
                                label="Fim"
                                format="HH:mm"
                                ampm={false}
                                value={endTime}
                                onChange={(newValue) => setEndTime(newValue)}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                                minutesStep={60}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </FormControl>
            <Divider sx={{ my: 2 }} />
            <FormControl fullWidth>
                <Stack spacing={2} direction="row">
                    <Button variant="text" onClick={() => navigate(-1)}>
                        Voltar
                    </Button>
                    <AnimateButton>
                        <Button fullWidth type="button" variant="contained" onClick={() => handleSave()}>
                            Salvar
                        </Button>
                    </AnimateButton>
                </Stack>
            </FormControl>
        </>
    );
};

export default UpdateProfile;
