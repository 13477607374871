import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    Modal,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { postUser, updateUser } from 'services/users';
import { enqueueSnackbar } from 'notistack';

const User = ({ open, onClose, user, fetchUsers }) => {
    const userData = {
        id: null,
        name: '',
        role: 'user',
        email: '',
        password: '',
        password_confirmation: ''
    };

    const [inputs, setInputs] = useState(userData);
    const [errors, setErrors] = useState({});

    const handleClose = () => {
        onClose();
    };

    const handleCreate = () => {
        postUser(inputs)
            .then((response) => {
                fetchUsers();
                handleClose();
                enqueueSnackbar('Usuário cadastrado com sucesso', { variant: 'success' });
            })
            .catch((error) => {
                setErrors(error.response?.data.errors);
                console.log(error);
            });
    };

    const handleUpdate = () => {
        updateUser(inputs.id, inputs)
            .then((response) => {
                fetchUsers();
                handleClose();
                enqueueSnackbar('Usuário atualizado com sucesso', { variant: 'success' });
            })
            .catch((error) => {
                setErrors(error.response?.data.errors);
                console.log(error);
            });
    };

    useEffect(() => {
        if (open && user) {
            setInputs({
                id: user.id,
                name: user.name,
                role: user.role,
                email: user.email
            });
            setErrors({});
        }
        if (open && !user) {
            setInputs(userData);
            setErrors({});
        }
    }, [open]);

    return (
        <Modal open={open} onClose={handleClose} sx={{ height: '100vh', overflowY: 'scroll' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    minWidth: 400,
                    maxWidth: 600,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Grid container spacing={1} display="flex" direction="row" justifyContent="space-between" alignItems="center">
                    <Typography color="GrayText" variant="h3" component="h1" fontWeight={100}>
                        {user?.id ? 'Editar usuário' : 'Novo usuário'}
                    </Typography>
                    <Grid item display="flex" justifyContent="flex-end">
                        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RadioGroup value={inputs.role} row>
                            <FormControlLabel
                                value="admin"
                                onChange={(e) => setInputs({ ...inputs, role: e.target.value })}
                                control={<Radio />}
                                label="Administrador"
                            />
                            <FormControlLabel
                                value="user"
                                onChange={(e) => setInputs({ ...inputs, role: e.target.value })}
                                control={<Radio />}
                                label="Usuário"
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={inputs.name}
                            fullWidth
                            label="Nome"
                            variant="outlined"
                            onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                            disabled={user?.external_auth}
                        />
                        <FormHelperText error>{errors?.name}</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={inputs.email}
                            fullWidth
                            label="E-mail"
                            variant="outlined"
                            onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
                            disabled={user?.external_auth}
                        />
                        <FormHelperText error>{errors?.email}</FormHelperText>
                    </Grid>
                    {!user?.external_auth && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    value={inputs.password}
                                    fullWidth
                                    label="Senha"
                                    type="password"
                                    variant="outlined"
                                    onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
                                />
                                <FormHelperText error>{errors?.password}</FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={inputs.password_confirmation}
                                    fullWidth
                                    label="Confirmação de senha"
                                    type="password"
                                    variant="outlined"
                                    onChange={(e) => setInputs({ ...inputs, password_confirmation: e.target.value })}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                user?.id ? handleUpdate() : handleCreate();
                            }}
                        >
                            {user?.id ? 'Salvar' : 'Cadastrar'}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default User;
