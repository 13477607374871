import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Paper,
    SpeedDial,
    SpeedDialAction,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { getUsers, deleteUser } from 'services/users';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { TablePaginationActions } from 'components/Pagination/TablePaginationActions';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { Microsoft } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import User from './User';
import { useDebouncedCallback } from 'use-debounce';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';

const GridUser = () => {
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);

    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [confirmDialog, setConfirmDialog] = useState(false);

    const [userModal, setUserModal] = useState(false);

    const actions = [
        {
            icon: (
                <Link href="#" onClick={() => setUserModal(true)}>
                    <PersonAddOutlinedIcon color="primary" />
                </Link>
            ),
            name: 'Novo usuário'
        }
    ];

    const handleDelete = () => {
        deleteUser(user.id).then(() => {
            setConfirmDialog(false);
            setUser(null);
            fetchUsers();
            enqueueSnackbar('Usuário excluído com sucesso', { variant: 'success' });
        });
    };

    const handleSearch = useDebouncedCallback((keyword) => {
        setKeyword(keyword);
    }, 1000);

    const fetchUsers = () => {
        const data = {
            keyword: keyword,
            perPage: rowsPerPage,
            page: page + 1
        };
        getUsers(data).then(({ data }) => {
            setUsers(data.data);
        });
    };

    useEffect(() => {
        fetchUsers();
    }, [keyword]);

    return (
        <MainCard title="Usuários" icon={<PeopleAltOutlinedIcon color="primary" />}>
            <SnackbarProvider />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Pesquisar"
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </Grid>
            </Grid>
            <TableContainer sx={{ mt: 2, height: '100%', padding: 0 }} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>Perfil</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>
                                    {user.external_auth ? (
                                        <Tooltip title="Autenticação externa" placement="top">
                                            <Microsoft color="primary" fontSize="small" />
                                        </Tooltip>
                                    ) : (
                                        ''
                                    )}
                                </TableCell>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.role === 'admin' ? 'Administrador' : 'Usuário'}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title="Editar" placement="top">
                                        <IconButton
                                            aria-label="edit"
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                setUser(user);
                                                setUserModal(true);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Excluir" placement="top">
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            color="error"
                                            onClick={() => {
                                                setUser(user);
                                                setConfirmDialog(true);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                        {users.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    Nenhum registro encontrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                colSpan={5}
                                count={users.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage="Registros por Página"
                                onPageChange={() => {}}
                                onRowsPerPageChange={() => {}}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
                <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: 'absolute', bottom: 16, right: 16 }}
                    icon={<SpeedDialIcon />}
                >
                    {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                    ))}
                </SpeedDial>
            </TableContainer>
            <User
                open={userModal}
                onClose={() => {
                    setUserModal(false);
                    setUser(null);
                }}
                user={user}
                fetchUsers={fetchUsers}
            />
            <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
                <DialogTitle id="alert-dialog-title">{'Atenção!'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Você tem certeza que deseja excluir esse usuário? Os agendamentos criados por ele também serão excluídos
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialog(false)}>Não</Button>
                    <Button onClick={handleDelete}>Sim</Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
};

export default GridUser;
